import { BunyanLoggerFactory } from "./BunyanLoggerFactory";
import { ContextDecorator, mergeContext, MetaInfo } from "../Context/Context";
import { LogLevel } from "./logLevel";
import { LogStream } from "./LogStream";
import { Bunyan } from "./LogStreams/Bunyan";

export type LogConfig = {
  isLocal: boolean;
  level?: LogLevel | string;
  logName?: string;
};

export class LoggerClient {
  private contextDecorator?: ContextDecorator;
  private logStream: LogStream;

  constructor(options?: LogConfig, contextDecorator?: ContextDecorator, logStream?: LogStream) {
    const opts: LogConfig = Object.assign(
      {
        isLocal: false,
        level: "default",
        logName: "default",
      },
      options ?? {}
    );
    this.contextDecorator = contextDecorator;
    if (!logStream) {
      logStream = new Bunyan(BunyanLoggerFactory(opts));
    }
    this.logStream = logStream;
  }

  public debug = (message: string, meta?: MetaInfo): void => {
    const context = mergeContext(meta, this.contextDecorator);
    this.logStream.debug(message, context);
  };

  public info = (message: string, meta?: MetaInfo): void => {
    const context = mergeContext(meta, this.contextDecorator);
    this.logStream.info(message, context);
  };

  public warn = (message: string, meta?: MetaInfo): void => {
    const context = mergeContext(meta, this.contextDecorator);
    this.logStream.warn(message, context);
  };

  public error = (message: string, meta?: MetaInfo): void => {
    const context = mergeContext(meta, this.contextDecorator);
    this.logStream.error(message, context);
  };
}
