import { Console } from "./Logging/LogStreams/Console";
import { LoggerClient } from "./Logging/LoggerClient";

let logger: LoggerClient = new LoggerClient(undefined, undefined, new Console());

export const setLoggerClient = (loggerInst: LoggerClient): void => {
  logger = loggerInst;
};

export { logger };
