import { Context as LambdaContext } from "aws-lambda";
import { ContextDecorator } from "./Context";
import { MetaInfo } from "../Context/Context";
import cloneDeep from "lodash/cloneDeep";

type AwsLambdaEvent = Record<string, unknown> | Record<string, unknown>[];

export class AWSLambdaContextDecorator implements ContextDecorator {
  private executionContext?: LambdaContext;
  private eventContext?: AwsLambdaEvent;

  constructor(context?: LambdaContext, event?: AwsLambdaEvent) {
    this.executionContext = context;
    this.eventContext = event;
  }

  decorate = (meta?: MetaInfo): MetaInfo | undefined => {
    let clonedMeta = cloneDeep(meta);
    if (this.executionContext !== undefined) {
      const { awsRequestId, functionName, functionVersion } = this.executionContext;

      clonedMeta = Object.assign(clonedMeta ?? {}, {
        _context: {
          awsRequestId,
          functionName,
          functionVersion,
        },
      });
    }

    if (this.eventContext !== undefined) {
      clonedMeta = Object.assign(clonedMeta, { _event: this.eventContext });
    }

    return clonedMeta;
  };
}
