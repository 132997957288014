import bunyan from "bunyan";
import PrettyStream from "bunyan-prettystream";
import { LogConfig } from "./LoggerClient";
import { LogLevel, getLogLevel } from "./logLevel";

export const BunyanLoggerFactory = (options: LogConfig): bunyan => {
  let level, stream, type;
  const name = options.logName ?? "default";

  if (options.isLocal) {
    level = getLogLevel(options, LogLevel.DEBUG);

    const prettyStdOut = new PrettyStream();
    prettyStdOut.pipe(process.stdout);

    stream = prettyStdOut;
    type = "raw";
  } else {
    level = getLogLevel(options, LogLevel.INFO);
    stream = process.stdout;
    type = "stream";
  }

  return bunyan.createLogger({
    name,
    streams: [
      {
        stream,
        level,
        type,
      },
      // {
      //   stream: new PublishMessageTransport(),
      //   level: "error",
      //   type: "raw",
      //   reemitErrorEvents: true,
      // },
    ],
  });
};
