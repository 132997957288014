import Clock from "./clock";
import { DateTime } from "luxon";
import { CalendarDateTime, EfaDateTime } from "@edfenergy/shift-desk-efa-calendar";

export default class SystemClock implements Clock {
  public now(): CalendarDateTime {
    return new CalendarDateTime(DateTime.now());
  }

  public currentEfaDate(): EfaDateTime {
    return this.now().toEfaDateTime();
  }
}
