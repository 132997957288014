import cloneDeep from "lodash/cloneDeep";

export type MetaInfo = Record<string, unknown> | undefined;

export interface ContextDecorator {
  decorate(meta?: MetaInfo): MetaInfo | undefined;
}

export const parseMeta = (object: MetaInfo): MetaInfo => {
  const clonedObject = cloneDeep(object);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parseBlackList = (node: any) => {
    const regExReplace = [/BEGIN CERTIFICATE/g, /BEGIN PRIVATE KEY/g];
    const minimumMatchCount = 1;
    const shouldRemove = (value: unknown) => {
      if (typeof value === "string") {
        return regExReplace.map(regExp => value.match(regExp)).filter(m => m !== null).length >= minimumMatchCount;
      }
      return false;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let returnObject: any;
    if (Array.isArray(node)) {
      returnObject = [];
    } else {
      returnObject = {};
    }
    Object.keys(node).forEach(key => {
      const value = node[key];
      if (!shouldRemove(key)) {
        if (shouldRemove(value)) {
          returnObject[key] = "<< REMOVED >>";
        } else if (typeof value === "object" && value !== null) {
          returnObject[key] = parseBlackList(value);
        } else {
          returnObject[key] = value;
        }
      }
    });
    return returnObject;
  };
  const parseCircularReferences = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    node: any,
    circularReferenceCheck = new Set()
  ) => {
    circularReferenceCheck.add(node);

    Object.keys(node).forEach(key => {
      const value = node[key];
      // check for cisrcular reference
      if (circularReferenceCheck.has(value)) {
        node[key] = "<< CIRCULAR REFERENCE >>";
      } else if (typeof value === "object" && value !== null) {
        parseCircularReferences(value, circularReferenceCheck);
      }
    });

    circularReferenceCheck.delete(node);
  };
  parseCircularReferences(clonedObject);
  return parseBlackList(clonedObject);
};

export const mergeContext = (meta?: MetaInfo, decorator?: ContextDecorator): MetaInfo => {
  if (decorator) {
    meta = decorator.decorate(meta);
  }

  if (meta !== undefined) {
    meta = parseMeta(meta);
  }

  return meta;
};
