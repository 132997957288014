/* eslint-disable no-console */
import { MetaInfo } from "../../Context/Context";
import { LogStream } from "../LogStream";

export class Console implements LogStream {
  debug = (message: string, context?: MetaInfo): void => {
    if (context) {
      console.log(context, message);
    } else {
      console.log(message);
    }
  };

  info = (message: string, context?: MetaInfo): void => {
    if (context) {
      console.info(context, message);
    } else {
      console.info(message);
    }
  };

  warn = (message: string, context?: MetaInfo): void => {
    if (context) {
      console.warn(context, message);
    } else {
      console.warn(message);
    }
  };

  error = (message: string, context?: MetaInfo): void => {
    if (context) {
      console.error(context, message);
    } else {
      console.error(message);
    }
  };
}
