import bunyan from "bunyan";
import { MetaInfo } from "../../Context/Context";
import { LogStream } from "../LogStream";

export class Bunyan implements LogStream {
  private bunyan: bunyan;

  constructor(bunyan: bunyan) {
    this.bunyan = bunyan;
  }

  debug = (message: string, context?: MetaInfo): void => {
    if (context) {
      this.bunyan.debug(context, message);
    } else {
      this.bunyan.debug(message);
    }
  };

  info = (message: string, context?: MetaInfo): void => {
    if (context) {
      this.bunyan.info(context, message);
    } else {
      this.bunyan.info(message);
    }
  };

  warn = (message: string, context?: MetaInfo): void => {
    if (context) {
      this.bunyan.warn(context, message);
    } else {
      this.bunyan.warn(message);
    }
  };

  error = (message: string, context?: MetaInfo): void => {
    if (context) {
      this.bunyan.error(context, message);
    } else {
      this.bunyan.error(message);
    }
  };
}
