import { CalendarDateTime, EfaDateTime } from "@edfenergy/shift-desk-efa-calendar";
import { DateTime } from "luxon";
import Clock from "./clock";

export default class FrozenClock implements Clock {
  private dateTime: CalendarDateTime;

  constructor(dateTime: CalendarDateTime) {
    this.dateTime = dateTime;
  }

  public now(): CalendarDateTime {
    return this.dateTime;
  }

  public currentEfaDate(): EfaDateTime {
    return this.dateTime.toEfaDateTime();
  }
}
