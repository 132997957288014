import Clock from "./clock";
import { DateTime } from "luxon";
import { CalendarDateTime, EfaDateTime } from "@edfenergy/shift-desk-efa-calendar";

export default class AdvancingClock implements Clock {
  private startFrom: CalendarDateTime;
  private instantiationDate: CalendarDateTime;

  constructor(startDate: CalendarDateTime, instantiationDate: CalendarDateTime) {
    this.startFrom = startDate;
    this.instantiationDate = instantiationDate;
  }

  public now(): CalendarDateTime {
    return this.currentDateTime();
  }

  public currentEfaDate(): EfaDateTime {
    return this.now().toEfaDateTime();
  }

  private currentDateTime(): CalendarDateTime {
    const advancedBy = DateTime.now().diff(this.instantiationDate.toLuxon());
    return this.startFrom.plus(advancedBy);
  }
}
