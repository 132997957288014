import { LogConfig } from "./LoggerClient";

export enum LogLevel {
  DEBUG = "debug",
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}

export const getLogLevel = (config: LogConfig, defaultLevel: LogLevel): LogLevel => {
  const configLevel = config.level;
  return configLevel !== "default" && configLevel !== undefined && configLevel !== null
    ? (configLevel as LogLevel)
    : defaultLevel;
};
